import React, { useState, useEffect } from "react";
import acko from "../../Assets/Partners_logo/BOOKMYINSURANCE/acko.jpg";
import PremiumContainer from "./PremiumContainer";
import backIcon from "../../Assets/Images/backIcon.svg";
import govt from "../../Assets/Images/govt.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { allBodyDataAction } from "../../redux/Reducers/bodyDataReducer";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { carDetailsforProposal } from "../../utils/commonUtilsFinal";
import Navbar from "../Navbar/Navbar";
import InputFields from "../InputFields/InputFields";
import DropDown from "../DropDown/DropDown";
import SearchSelect from "../DropDown/Search Select/SearchSelect";
import { previousfilenameAction } from "../../redux/Reducers/bodyDataReducer.js";
import Botton from "../Button/Button";
import Modal from "react-modal";
import failedDoc from "../../Assets/Images/failedDoc.svg";
import upload from "../../Assets/Images/uploadKyc.svg";
import sucess from "../../Assets/Images/uploadSucess.svg";
import blueUpload from "../../Assets/Images/upload-blue.svg";
import close from "../../Assets/Images/Close.svg";
import err from "../../Assets/Images/err.svg";
import {
    paymentWebhookAction
} from "../../redux/Actions/paymentWebhookAction";
import {
    ackoautoKycAction,
    ackoManualKycAction,
    getPanDetailsByNo
} from "../../redux/Actions/ackoApiAction";
import moment from "moment";
import loader2 from "../../Assets/Loader/Loader.gif";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader.jsx";
import { ackopdfDownloadAction } from "../../redux/Actions/ackoApiAction";
import { getCityAction, getCityAction1 } from "../../redux/Actions/stateDetails";

const customStyles = {
    content: window.innerWidth <= 610
        ? {
            top: "auto",
            left: "0",
            right: "0",
            bottom: "0",
            margin: "0",
            transform: "none",
            borderRadius: "10px 10px 0 0",
            padding: "20px",
            height: "auto",
        }
        : {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "10px",
            padding: "20px",
            height: "auto",
        },
};

const AckoKycForm = (props) => {
    const { bottomFooter } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const search = useLocation().search;
    const proposalId = new URLSearchParams(search).get("proposal_id");
    const transaction_url_id = new URLSearchParams(search).get("id");
    const [Loading, setLoading] = useState(false)
    const [intitialLoading, setintitialLoading] = useState(false)
    const [CKYCrejected, setCKYCrejected] = useState(false);
    const [KYCmethod, setKYCmethod] = useState(null);
    const [ShowKYClist, setShowKYClist] = useState(false);
    const [AadharImgName, setAadharImgName] = useState("");
    const [AadharImgBackName, setAadharImgBackName] = useState("");
    const [PanImgName, setPanImgName] = useState("");
    const [CINImgName, setCINImgName] = useState("");
    const [Image, setImage] = useState(null);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [errors, setErrors] = useState({});
    const [ReEnterContent, setReEnterContent] = useState("");
    const [ShowSuccesMsg, setShowSuccesMsg] = useState(false);
    const [Day, setDay] = useState(null);
    const [Month, setMonth] = useState(null);
    const [Year, setYear] = useState(null);
    const [ManualKycMode, setManualKycMode] = useState(false);
    const [proposalData, setProposalData] = useState("");
    const [UploadImgType, setUploadImgType] = useState("");
    const [ShowUploadModal, setShowUploadModal] = useState(false);
    const [IsModalAadhar, setIsModalAadhar] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    let bodyData = useSelector((state) => {
        return state.bodyData;
    });

    const ackoQuote = useSelector((state) => {
        return state?.ackoQuickQuote;
    });

    let quoteData = {};
    quoteData = ackoQuote;

    let propsalData = {
        carOwner: "individualOwner",
        firstName: "",
        fathersFirstName: null,
        fathersLastName: null,
        lastName: "",
        companyName: "",
        email: "",
        appointeeName: "",
        appointeeRelation: "",
        paExclusionReason: "",
        companyName: "",
        email: "",
        isNilDepInPrevPolicy: "",
        phoneNumber: bodyData?.data?.mobileNo
            ? Number(bodyData?.data?.mobileNo)
            : "",
        alternatePhoneNumber: "",
        gstNumber: "",
        gender: "",
        maritalStatus: "",
        dob: "",
        nomineeName: "",
        nomineeAge: "",
        nomineeRelation: "",
        addressOne: "",
        addressTwo: "",
        state: "",
        city: "",
        pincode: bodyData?.data?.pincode ? Number(bodyData?.data?.pincode) : "",
        prevPolicyNumber: "",
        prevOdPolicyNumber: "",
        prevPolicyNameInsurer: "",
        prevOdPolicyNameInsurer: "",
        chassisNumber: "",
        engineNumber: "",
        carIsInLoan: "",
        isSelfInspection: null,
        kycAadharNumber: "",
        CertificateOfIncorporationNumber: "",
        CertificateOfIncorporationFileFileType: "",
        CertificateOfIncorporationFile: "",
        kycPanNumber: "",
        kycProfileImg: "",
        kycProfileImgFileType: "",
        kycAadharImg: "",
        kycAadharImgBack: "",
        kycAadharImgFileType: "",
        kycAadharImgBackFileType: "",
        kycPanImgFileType: "",
        kycPanImg: "",
        carHypothecationCompany: "",
        typeOfKycDocument: "PAN Card",  // Aadhar Card, PAN Card
        isCarPUC: "Yes",
        ckycResponseNumber: "",
        ckycUniqueId: "",
        phoneNumberforShareLink: "",
        emailforShareLink: "",
    };

    const [formData, setFormData] = useState(propsalData);

    const handleStateValue = (value) => {
        setFormData((prevItems) => ({
            ...prevItems,
            typeOfKycDocument: value?.value,
        }));
    };

    let kycMethodsList = [
        { label: "PAN Card", value: "PAN Card" },
        { label: "Aadhar Card", value: "Aadhar Card" },
    ];

    const handlePopUp = () => {
        if (ShowKYClist) {
            setShowKYClist(false);
        }
    };

    // input handler for Inputs
    const handleInputChange = (event, constName) => {
        const { name, value, checked } = event?.target || {};
        if (name && errors) {
            delete errors[name]
        }
        if (
            name === "kycAadharImg" ||
            name === "kycProfileImg" ||
            name === "kycPanImg" ||
            name === "kycAadharImgBack" ||
            name === "CertificateOfIncorporationFile"
        ) {
            const file = event.target.files[0];
            let uploadedFileName = "";
            if (file) {
                setShowUploadModal(false);
                const reader = new FileReader();
                reader.onload = (e) => {
                    const base64Data = e.target.result.split(",")[1];
                    const fileExtension = "." + file.type.split("/").pop();

                    setFormData((prevItems) => ({
                        ...prevItems,
                        [name]: base64Data,
                        [`${name}FileType`]: fileExtension,
                        kycProfileImg: base64Data,
                        base64DataFileType: fileExtension,
                        kycProfileImgFileType: fileExtension,
                    }));
                };

                reader.readAsDataURL(file);
                uploadedFileName = file.name;
                const uploadedFileType = file.type;

                if (uploadedFileName.length > 40) {
                    uploadedFileName = "..." + uploadedFileName.slice(-7);
                }

                if (name === "kycAadharImg") {
                    setAadharImgName(uploadedFileName);
                } else if (name === "kycAadharImgBack") {
                    dispatch(previousfilenameAction(uploadedFileName));
                    setAadharImgBackName(uploadedFileName);
                } else if (name === "kycPanImg") {
                    setPanImgName(uploadedFileName);
                } else if (name === "kycProfileImg") {
                    // setImage(URL.createObjectURL(file));
                    // setProfileImgName(uploadedFileName);
                } else if (name == "CertificateOfIncorporationFile") {
                    // setCINImgName(uploadedFileName);
                }

                setShowSuccesMsg(true);
            }
        } else if (name == "kycAadharNumber") {
            if (value < 999999999999) {
                setFormData((prevItems) => ({
                    ...prevItems,
                    kycAadharNumber: value,
                }));
            }
        } else if (name === "kycPanNumber") {
            if (value.length < 13) {
                setFormData((prevItems) => ({
                    ...prevItems,
                    kycPanNumber: value?.toUpperCase(),
                }));
            }
        } else {
            setFormData((prevItems) => ({
                ...prevItems,
                [name]: value,
            }));
        }
    };

    useEffect(() => {
        (async function () {
            const proposalId = new URLSearchParams(search).get("proposal_id");
            const transaction_url_id = new URLSearchParams(search).get("id");
            let statusData = {
                transactionid: proposalId,
                type: "getpaymentdata",
            };
            const paymentResponse = await dispatch(
                paymentWebhookAction(statusData)
            );
            const { paymentinfo } = paymentResponse?.payload?.data;
            const { acko } = JSON.parse(paymentinfo[0]?.body_data);
            console.log("ackoackoackoacko", acko.data.proposal.insured[0]);
            setProposalData(acko.data.proposal.insured[0])

            //check if pan details are fetched
            if (acko.data.proposal.insured[0]?.asset_details?.asset_id) {
                setintitialLoading(true)
                let apiData = {
                    data: {
                        vehicleNo: acko.data.proposal.insured[0]?.asset_details?.asset_id
                    }
                }
                console.log("apiData", apiData)
                let apiResp = await dispatch(getPanDetailsByNo(apiData));
                console.log("api", apiResp)
                if (apiResp?.payload?.status) {
                    if (apiResp?.payload?.data?.length) {
                        setFormData((prevItems) => ({
                            ...prevItems,
                            kycPanNumber: apiResp?.payload?.data[0]?.pan_number?.toUpperCase()
                        }));
                        setMonth(moment(apiResp?.payload?.data[0]?.dob).format("MM"))
                        setDay(moment(apiResp?.payload?.data[0]?.dob).format("DD"))
                        setYear(moment(apiResp?.payload?.data[0]?.dob).format("YYYY"))
                    }
                    setintitialLoading(false)
                }
            }
        })();
    }, []);

    useEffect(() => {
        if (ShowSuccesMsg) {
            setTimeout(() => {
                setShowSuccesMsg(false);
            }, 1000);
        }
    }, [ShowSuccesMsg]);

    // validation :-
    const validateInput = () => {
        const newErrors = {};

        let regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        var regaadhar = /^[2-9]{1}[0-9]{11}$/;

        if (formData?.typeOfKycDocument === "PAN Card" && !formData?.kycPanNumber && !CKYCrejected) {
            newErrors.kycPanNumber = "PAN Card is required";
        }

        if (formData?.typeOfKycDocument === "PAN Card" && formData?.dob === "null-null-null" && !CKYCrejected) {
            if (!proposalData?.user?.is_corporate) {
                newErrors.dob = "Date of Birth is required";
            } else {
                newErrors.dob = "Date of Incorporation is required";
            }
        }

        if (formData?.typeOfKycDocument === "PAN Card" && formData?.dob !== "null-null-null" && !CKYCrejected) {
            if (!Day) {
                newErrors.dob = "Date of Birth is required";
            } else if (!Month) {
                newErrors.dob = "Date of Birth is required";
            } else if (!Year) {
                newErrors.dob = "Date of Birth is required";
            }
        }

        if (IsModalAadhar && !formData?.kycAadharNumber) {
            newErrors.kycAadharNumber = "Aadhar Number is required";
        }

        if (
            formData?.typeOfKycDocument === "Aadhar Card" &&
            !formData?.kycAadharNumber
        ) {
            newErrors.kycAadharNumber = "Aadhar Card is required";
        }


        //set validation for city field and  address

        if (formData?.typeOfKycDocument === "Aadhar Card" && ManualKycMode) {
            if (!formData.addressOne) {
                newErrors.addressOne = "Address is required";
            }

            if (!formData.city) {
                newErrors.city = " City is Required ";
            }
        }




        if (
            formData?.typeOfKycDocument === "Aadhar Card" &&
            !formData?.kycAadharImg && ManualKycMode
        ) {
            newErrors.kycAadharImg = "Aadhar Front Image is required";
        }

        if (
            formData?.typeOfKycDocument === "Aadhar Card" &&
            !formData?.kycAadharImgBack && ManualKycMode
        ) {
            newErrors.kycAadharImgBack = "Aadhar Back Image is required";
        }

        if (
            formData?.typeOfKycDocument === "Aadhar Card" &&
            !formData?.gender && ManualKycMode
        ) {
            newErrors.gender = "Gender is required";
        }

        if (
            formData?.typeOfKycDocument === "Aadhar Card" &&
            !formData?.fathersFirstName && ManualKycMode
        ) {
            newErrors.fathersFirstName = "Father First Name is required";
        }

        if (
            formData?.typeOfKycDocument === "Aadhar Card" &&
            !formData?.fathersLastName && ManualKycMode
        ) {
            newErrors.fathersLastName = "Father Last Name is required";
        }

        if (formData.kycPanNumber) {
            if (!regpan.test(formData.kycPanNumber)) {
                newErrors.kycPanNumber = "Pan number is in Invalid Format";
            }
        }

        if (formData.kycAadharNumber) {
            if (!regaadhar.test(formData.kycAadharNumber)) {
                newErrors.kycAadharNumber = "Aadhar number is in Invalid Format";
            }
        }



        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // auto kyc function :-
    const handleAutoKycForm = async (kycDocType, contentType, buttonType) => {
        // debugger;
        let valid = validateInput(formData);
        if (valid) {
            if (kycDocType === "PAN Card" && !contentType && !buttonType) {
                setLoading(true);
                // call auto kyc api 
                let kycApiData = {
                    "kycPanNumber": formData?.kycPanNumber,
                    "dob": formData?.dob,
                    "customerName": proposalData?.user?.name,
                    "proposal_id": proposalId,
                }
                console.log("kycApiData", kycApiData)
                let autoKycResp = await dispatch(ackoautoKycAction(kycApiData));
                console.log("autoKyc", autoKycResp)
                if (autoKycResp?.payload?.status) {
                    setTimeout(async () => {
                        const pdfData = await dispatch(
                            ackopdfDownloadAction({
                                data: {
                                    proposal_id: proposalId,
                                },
                            })
                        );
                        if (pdfData?.payload?.data?.pdfBase64) {
                            setLoading(false);
                            // payment download page
                            navigate(
                                `/Payment-Redirect?companyName=acko&proposal_id=${proposalId}&policy_number=${pdfData?.payload?.data.policyNo}`
                            );
                        } else {
                            navigate(
                                `/Payment-Redirect?companyName=acko&proposal_id=${proposalId}`
                            );
                        }
                    }, 8000);
                } else {
                    setLoading(false);
                    if (proposalData?.user?.is_corporate) {
                        console.log("Corporate KYC Completed");
                    } else {
                        setIsOpen(true);
                        setIsModalAadhar(true);
                    }
                }
            } else if (kycDocType === "Aadhar Card" && contentType === "modal" && !buttonType) {
                // alert(kycDocType + "<---kycDocType");
                let validModal = validateInput();
                if (validModal) {
                    setIsOpen(false);
                    setManualKycMode(true);
                    setCKYCrejected(true);
                    setFormData((prevItems) => ({
                        ...prevItems,
                        typeOfKycDocument: "Aadhar Card",
                    }))
                    setIsModalAadhar(false);
                }

            }
        }
    };

    const handleSubmitForm = async () => {
        let valid = validateInput(formData);
        if (valid) {
            setLoading(true);
            //call manual kyc api 
            let kycApiData = {
                kycAadharImg: formData?.kycAadharImg,
                kycAadharNumber: formData?.kycAadharNumber,
                kycAadharImgFileType: formData?.kycAadharImgFileType,
                kycAadharImgBack: formData?.kycAadharImgBack,
                kycAadharImgBackFileType: formData?.kycAadharImgBackFileType,
                kycPanNumber: formData?.kycPanNumber,
                gender: formData?.gender,
                address: proposalData?.user?.address,
                fathersFirstName: formData?.fathersFirstName,
                fathersLastName: formData?.fathersLastName,
                address: formData?.addressOne,
                proposal_id: proposalId,
                city: formData?.city ? formData?.city : proposalData?.user?.city,
                pincode: proposalData?.user?.pincode,

                AadharImgName: AadharImgName,
                AadharImgBackName: AadharImgBackName
            }
            console.log("kycApiData", kycApiData)
            let manualKycResp = await dispatch(ackoManualKycAction(kycApiData));
            console.log("manualKyc", manualKycResp)
            if (manualKycResp?.payload?.status) {
                setTimeout(async () => {
                    const pdfData = await dispatch(
                        ackopdfDownloadAction({
                            data: {
                                proposal_id: proposalId,
                            },
                        })
                    );
                    if (pdfData?.payload?.data?.pdfBase64) {
                        setLoading(false);
                        // payment download page
                        navigate(
                            `/Payment-Redirect?companyName=acko&proposal_id=${proposalId}&policy_number=${pdfData?.payload?.data.policyNo}`
                        );
                    } else {
                        setLoading(false);
                        navigate(
                            `/Payment-Redirect?companyName=acko&proposal_id=${proposalId}`
                        );
                    }
                }, 8000);
            } else {
                setLoading(false);
                alert("Manual Kyc failed, Kindly try again !")
            }
        }
    };

    function closeModal(otp) {
        setIsOpen(false);
    }

    let genderList = [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
        { label: "Transgender", value: "Transgender" },
    ];

    const handleGenderValue = (data) => {
        if (data?.value && errors) {
            delete errors["gender"]
        }
        setFormData((prevItems) => ({
            ...prevItems,
            gender: data?.value,
        }))
    };

    useEffect(() => {
        setFormData((prevItems) => ({
            ...prevItems,
            dob: `${Year}-${Month}-${Day}`
        }))
    }, [Day, Month, Year]);

    const handleUploadModal = (imgType) => {
        setUploadImgType(imgType);
        setShowUploadModal(true);
    }

    // adding for city Api 
    useEffect(() => {
        dispatch(getCityAction1());
    }, [])

    const cityListResponse = useSelector((state) => {
        return state?.getCityDetails1?.data?.data?.rtocity;
    });

    let response_city = cityListResponse?.map((data, index) => ({
        label: data?.city_name,
        value: data?.city_name,
    }));

    const handleCityValue = (value) => {
        delete errors?.city
        setFormData((prevItems) => ({
            ...prevItems,
            city: value?.value,
        }));
    };

    return (
        <>
            {/* LOADER */}

            <div id="cover-spin" style={{ display: (!proposalData || intitialLoading) ? "" : "none" }}>
                <img src={loader2} className="gifLoader" />
            </div>

            <Navbar />

            {/* form  */}

            <div className="PropsalFormContainer_padding PropsalFormContainer">
                <div className="stepperWithForm maxWidth">
                    <div className="PropsalFormContainer_parent_acko PropsalFormContainer_parent">
                        <div className="breakupContainer breakupContainer_ackoKyc PropsalFormContainer_child">
                            <div className="PremiumContainer-div">
                                <div className="premiumContainer premiumContainer_acko_kyc">
                                    {/* MMV AND POLICY DETAILS */}

                                    <div className="pol-details-premiumContainer">
                                        <div className="car-details-premContainer">
                                            <p>{(proposalData?.plan?.id?.replaceAll('_', ' '))?.toUpperCase()}</p>
                                            <p>{proposalData?.asset_details?.asset_id ? proposalData?.asset_details?.asset_id : "NEW"}</p>
                                        </div>
                                        <div className="mmv_premiumContainer">
                                            <p>{proposalData?.asset_details?.asset_properties?.make}</p>
                                            <p>{proposalData?.asset_details?.asset_properties?.model + " " +
                                                proposalData?.asset_details?.asset_properties?.variant + " " +
                                                "(" + proposalData?.asset_details?.asset_properties?.cc + "cc" + ")"}</p>
                                        </div>
                                        <div className="policy-det-premContent">
                                            <div className="policy-det-premContent-block">
                                                <p>Policy Start Date</p>
                                                <p>
                                                    {moment(new Date(proposalData?.plan?.policy_start_date)).format("DD MMM YYYY")}
                                                </p>
                                            </div>
                                            <div className="policy-det-premContent-block">
                                                <p>New NCB</p>
                                                <p>{proposalData?.new_ncb?.value ? (proposalData?.new_ncb?.value + " %") : "NA"}</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* COMPANY DETAILS */}

                                    <div className="amountContainer amountContainer_acko">
                                        <img src={acko} />
                                        <div className="amt_prem">
                                            <p>₹ {proposalData?.plan?.total_price?.gross_premium}</p>
                                            <p>Price inclusive of GST</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            id="PropsalFormContainer_child-formFields"
                            className="formContainer formContainer_acko PropsalFormContainer_child formContainer-collapsible"
                        >
                            <div className="titleContainer titleContainer_acko">
                                <p></p>
                                <p>All fields are mandatory</p>
                            </div>

                            <div className="kyc_container_photo_conteent">
                                <div className="kyc_container_content">
                                    <div className="kyc_info_container">
                                        <p className="title_kyc_pf">Update your KYC</p>
                                        <p className="small_text"> {kycContent} </p>
                                        <p className="title_kyc_pf"> {kycContentTwo} </p>
                                    </div>
                                </div>
                            </div>

                            {/* form container */}

                            <div className="acko_kyc_container">
                                <form
                                    className="formSection-pform"
                                    onClick={handlePopUp}
                                    action=""
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    {!CKYCrejected && (
                                        <>
                                            <div className="input_group_pform input_group_pform_kyc_acko">
                                                <div className="inputGropOne_form inputGrop ">
                                                    <p className="kyc_label_acko">
                                                        Enter Your PAN Number
                                                    </p>
                                                    <input
                                                        type="text"
                                                        name="kycPanNumber"
                                                        id="kycPanNumber"
                                                        placeholder="xxxxx1234x"
                                                        className={`acko_kyc_input ${errors?.kycPanNumber ? "input-err" : ""}`}
                                                        value={formData?.kycPanNumber}
                                                        onChange={(e) => handleInputChange(e)}
                                                    />
                                                    {errors?.kycPanNumber && (
                                                        <p className="err-msg">{errors?.kycPanNumber}</p>
                                                    )}
                                                </div>
                                                <div className="inputGropOne_form inputGrop ">
                                                    <p className="kyc_label_acko">
                                                        {!proposalData?.user?.is_corporate ? "Enter Your Date of birth" : "Enter Date of Incorporation"}
                                                    </p>
                                                    <div className="custom_dob_container">
                                                        <InputFields
                                                            type="number"
                                                            value={Day}
                                                            containerID="custom_dob"
                                                            label="DD"
                                                            className={`custom_dob ${errors?.dob && !Day && 'input-err'} `}
                                                            onChangeEvent={(e) =>
                                                                setDay(
                                                                    e.target.value.length <= 2 &&
                                                                        e.target.value <= 31
                                                                        ? e.target.value
                                                                        : Day
                                                                )
                                                            }
                                                        />
                                                        <InputFields
                                                            type="number"
                                                            value={Month}
                                                            containerID="custom_dob"
                                                            label="MM"
                                                            className={` ${errors?.dob && !Month && 'input-err'} `}
                                                            onChangeEvent={(e) =>
                                                                setMonth(
                                                                    e.target.value.length <= 2 &&
                                                                        e.target.value <= 12
                                                                        ? e.target.value
                                                                        : Month
                                                                )
                                                            }
                                                        />
                                                        <InputFields
                                                            type="number"
                                                            value={Year}
                                                            containerID="custom_dob"
                                                            label="YYYY"
                                                            className={`custom_dob ${errors?.dob && !Year && 'input-err'} `}
                                                            onChangeEvent={(e) =>
                                                                setYear(
                                                                    e.target.value.length <= 4
                                                                        ? e.target.value
                                                                        : Year
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    {errors?.dob && (
                                                        <p className="err-msg">{errors?.dob}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {CKYCrejected && (
                                        <>
                                            <div className="input_group_pform">
                                                <div className="inputGropOne_form inputGrop ">
                                                    <p className="kyc_label_acko">Enter Aadhar Number</p>
                                                    <input
                                                        type="text"
                                                        name="kycAadharNumber"
                                                        id="kycAadharNumberAcko"
                                                        placeholder="xxxx xxxx xxxx"
                                                        className={`acko_kyc_input acko_kyc_input_case_two ${errors?.kycAadharNumber ? "input-err" : ""}`}
                                                        value={formData?.kycAadharNumber}
                                                        onChange={(e) => handleInputChange(e)}
                                                    />
                                                    {errors?.kycAadharNumber && (
                                                        <p className="err-msg">{errors?.kycAadharNumber}</p>
                                                    )}
                                                </div>
                                                <div className="inputGropOne_form inputGrop inputGrop_padding ">
                                                    <SearchSelect
                                                        optionList={genderList}
                                                        handleSelect={handleGenderValue}
                                                        value={formData.gender}
                                                        ManualInpValue={formData}
                                                        placeholder="Select you gender"
                                                        label="Gender"
                                                        name="gender"
                                                        errState={errors?.gender ? true : false}
                                                        errMsg={errors?.gender}
                                                        errClassName="input-err"
                                                    />
                                                </div>
                                            </div>

                                            <div className="input_group_pform">
                                                <div className="inputGropOne_form inputGrop ">
                                                    <InputFields
                                                        placeholder="Father First Name "
                                                        id="fathersFirstName"
                                                        type="text"
                                                        name="fathersFirstName"
                                                        value={formData?.fathersFirstName}
                                                        errState={errors?.fathersFirstName ? true : false}
                                                        errMsg={errors?.fathersFirstName}
                                                        errClassName="input-err"
                                                        onChangeEvent={(e) => handleInputChange(e)}
                                                    />
                                                </div>
                                                <div className="inputGropOne_form inputGrop">
                                                    <InputFields
                                                        placeholder="Father Last Name "
                                                        id="fathersLastName"
                                                        type="text"
                                                        name="fathersLastName"
                                                        value={formData?.fathersLastName}
                                                        errState={errors?.fathersLastName ? true : false}
                                                        errMsg={errors?.fathersLastName}
                                                        errClassName="input-err"
                                                        onChangeEvent={(e) => handleInputChange(e)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="input_group_pform">
                                                <div className="inputGropOne_form inputGrop ">
                                                    <InputFields
                                                        value={formData?.addressOne}
                                                        placeholder="Address "
                                                        id="addressOne"
                                                        type="text"
                                                        name="addressOne"
                                                        errState={errors?.addressOne ? true : false}
                                                        errMsg={errors?.addressOne}
                                                        errClassName="input-err"
                                                        onChangeEvent={(e) => handleInputChange(e)}
                                                    />
                                                </div>
                                                <div className="inputGropOne_form inputGrop">
                                                    <SearchSelect
                                                        optionList={response_city}
                                                        handleSelect={handleCityValue}
                                                        value={formData?.city}
                                                        ManualInpValue={formData}
                                                        placeholder="City"
                                                        label="City"
                                                        name="city"
                                                        errState={errors?.city ? true : false}
                                                        errMsg={errors?.city}
                                                        errClassName="input-err"
                                                    // isDisabled={!formData?.city ? true : false}
                                                    />
                                                </div>
                                            </div>


                                            <div className="input_group_pform hideInMobile">
                                                <div className="inputGropOne_form inputGrop ">
                                                    <p className="kyc_label_acko">
                                                        Upload Aadhar Front Image
                                                    </p>
                                                    <div className={`inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc ${errors?.kycAadharImg && 'custom_acko_kyc_err'} `}>
                                                        {formData?.kycAadharImg ? (
                                                            <span className="successFull_msg">
                                                                Photo Uploaded Successfully
                                                            </span>
                                                        ) : (
                                                            <p>JPEG, PNG or JPG formats only</p>
                                                        )}

                                                        <input
                                                            type="file"
                                                            className="hideRealUploadBtn"
                                                            id="uploadImg-Aadhar"
                                                            accept="image/*, application/pdf"
                                                            name="kycAadharImg"
                                                            onChange={(e) => handleInputChange(e)}
                                                            hidden
                                                        />
                                                        <label
                                                            className={`customLabel-kyc_acko ${formData?.kycAadharImg && "customLabel-kyc_acko_active"} `}
                                                            htmlFor="uploadImg-Aadhar"
                                                        >
                                                            <p className="btn-kyc_acko">
                                                                <img
                                                                    src={formData?.kycAadharImg ? blueUpload : upload}
                                                                />
                                                                {
                                                                    formData?.kycAadharImg ? "Re-Upload File" : "Upload File"
                                                                }
                                                            </p>
                                                        </label>
                                                    </div>
                                                    {
                                                        errors?.kycAadharImg && (
                                                            <p className="err-msg"> {errors?.kycAadharImg} </p>
                                                        )
                                                    }
                                                </div>
                                                <div className="inputGropOne_form inputGrop ">
                                                    <p className="kyc_label_acko">
                                                        Upload Aadhar Back Image
                                                    </p>
                                                    <div className={`inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc ${errors?.kycAadharImgBack && 'custom_acko_kyc_err'} `}>
                                                        {formData?.kycAadharImgBack ? (
                                                            <span className="successFull_msg">
                                                                Photo Uploaded Successfully
                                                            </span>
                                                        ) : (
                                                            <p>JPEG, PNG or JPG formats only</p>
                                                        )}
                                                        <input
                                                            type="file"
                                                            className="hideRealUploadBtn"
                                                            id="kycAadharImgBack"
                                                            accept="image/*, application/pdf"
                                                            name="kycAadharImgBack"
                                                            onChange={(e) => handleInputChange(e)}
                                                            hidden
                                                        />
                                                        <label
                                                            className={`customLabel-kyc_acko ${formData?.kycAadharImgBack
                                                                ? "customLabel-kyc_acko_active"
                                                                : ""
                                                                } `}
                                                            htmlFor="kycAadharImgBack"
                                                        >
                                                            <p className="btn-kyc_acko">
                                                                <img
                                                                    src={
                                                                        formData?.kycAadharImgBack
                                                                            ? blueUpload
                                                                            : upload
                                                                    }
                                                                />
                                                                {
                                                                    formData?.kycAadharImgBack ? "Re-Upload File" : "Upload File"
                                                                }
                                                            </p>
                                                        </label>
                                                    </div>
                                                    {
                                                        errors.kycAadharImgBack && (
                                                            <p className="err-msg" > {errors?.kycAadharImgBack} </p>
                                                        )
                                                    }
                                                </div>
                                            </div>

                                            <div className="uploadContainer_mobile">
                                                <p className="kyc_label_acko" >Upload Aadhar Photo</p>
                                                <div className="uploadLabel_mobile_tab">
                                                    <div className="label_container_kyc_mob">
                                                        {/*  errors?.kycAadharImg */}
                                                        <img src={errors?.kycAadharImg ? err : sucess} />
                                                        <p style={{ fontSize: "13px" }} >Aadhar Front Photo</p>
                                                        <span onClick={() => handleUploadModal("Front")} className={errors?.kycAadharImg && "err-msg-acko"} > {formData?.kycAadharImg ? "Re-Upload" : "Upload"} </span>
                                                    </div>
                                                    <div className="label_container_kyc_mob">
                                                        {/* errors.kycAadharImgBack */}
                                                        <img src={errors?.kycAadharImgBack ? err : sucess} />
                                                        <p style={{ fontSize: "13px" }} >Aadhar Back Photo</p>
                                                        <span onClick={() => handleUploadModal("Back")} className={errors?.kycAadharImgBack && "err-msg-acko"} > {formData?.kycAadharImgBack ? "Re-Upload" : "Upload"} </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {!CKYCrejected ? (
                                        <section className="btnContainer_pform btnContainer_pform_collapsible">
                                            {
                                                Loading ? (
                                                    <ButtonLoader className="btn_kycForm_acko_loading" />
                                                ) : (
                                                    <Botton
                                                        text={
                                                            !CKYCrejected
                                                                ? "Validate your PAN"
                                                                : "Validate your Aadhar"
                                                        }
                                                        type="submit"
                                                        className="button btn_kycForm_acko"
                                                        event={() => handleAutoKycForm(!CKYCrejected ? "PAN Card" : "Aadhar Card")}
                                                    />
                                                )
                                            }
                                        </section>
                                    ) : (
                                        <section className="btnContainer_pform btnContainer_pform_collapsible">
                                            {
                                                Loading ? (
                                                    <ButtonLoader className="btn_kycForm_acko_loading" />
                                                ) : (
                                                    <Botton
                                                        text="Validate Aadhar Details"
                                                        type="submit"
                                                        className="button btn_kycForm_acko"
                                                        event={(e) => handleSubmitForm("Aadhar Card")}
                                                    />
                                                )
                                            }
                                        </section>
                                    )}

                                </form>
                            </div>
                        </div>
                    </div>

                    {/* footer */}

                    <div className="footer-parent-padding footer-parent-padding-acko">
                        <div className="footer_bottom_container maxWidth  footer_bottom_container_pForm ">
                            <p>{bottomFooter[0].contentOne}</p>
                        </div>
                        <div className="footer_bottom_containerTwo maxWidth ">
                            <div className="footer_bottom_containerTwo_part">
                                <p> {bottomFooter[0].contentTwo}</p>
                                <p>{bottomFooter[0].contentThree}</p>
                            </div>
                            <div className="footer_bottom_containerTwo_part">
                                <p>{bottomFooter[0].contentFour}</p>
                                <p>{bottomFooter[0].contentFive}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* AUTO KYC FAILED POP-UP */}

            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
            >
                <div className="acko_kyc_modal_container">

                    <img src={close} onClick={() => { setIsOpen(false); setIsModalAadhar(false); }} />

                    <div className="kyc_modal_msg_container">
                        <img src={failedDoc} />
                        <p>PAN Verification Failed!</p>
                    </div>

                    <div className="input_group_pform">
                        <div className="inputGropOne_form inputGrop ">
                            <p className="kyc_label_acko">Enter Aadhar Number</p>
                            <input
                                type="text"
                                name="kycAadharNumber"
                                id="kycAadharNumberAcko"
                                placeholder="xxxx xxxx xxxx"
                                className={`acko_kyc_input acko_kyc_input_case_two ${errors?.kycAadharNumber ? "input-err" : ""}`}
                                value={formData?.kycAadharNumber}
                                onChange={(e) => handleInputChange(e)}
                            />
                            {errors?.kycAadharNumber && (
                                <p className="err-msg">{errors?.kycAadharNumber}</p>
                            )}
                        </div>
                    </div>

                    <section className="">
                        <Botton
                            text="Continue with Aadhar Details"
                            type="submit"
                            className="button btn_kycForm_acko  btn_kycForm_acko_caseTwo"
                            event={(e) => handleAutoKycForm("Aadhar Card", "modal")}
                        // isDisabledButton={!formData?.kycAadharNumber ? true : false}
                        />
                    </section>
                </div>
            </Modal>

            {/* uploaded successfully modal */}

            <Modal
                isOpen={ShowSuccesMsg}
                style={customStyles}
            >
                <div className="uploaded_success_container">
                    <img src={sucess} />
                    <p>
                        Your file has been uplaoded <br /> successfully.
                    </p>
                </div>
            </Modal>

            {/* upload Modal */}

            <Modal
                isOpen={ShowUploadModal}
                style={customStyles}
            >
                <div className="upload_label_container">

                    {
                        UploadImgType === "Front" ? (
                            <div className="inputGropOne_form inputGrop  ">
                                <div className="inputGrop_modal_close_container">
                                    <img src={close} onClick={() => setShowUploadModal(false)} />
                                </div>
                                <p className="kyc_label_acko">
                                    Upload Aadhar Front Image
                                </p>
                                <div className={`inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc ${errors?.kycAadharImg && 'custom_acko_kyc_err'} `}>
                                    {formData?.kycAadharImg ? (
                                        <span className="successFull_msg">
                                            Photo Uploaded Successfully
                                        </span>
                                    ) : (
                                        <p>JPEG, PNG or JPG formats only</p>
                                    )}
                                    <input
                                        type="file"
                                        className="hideRealUploadBtn"
                                        id="uploadImg-Aadhar"
                                        accept="image/*, application/pdf"
                                        name="kycAadharImg"
                                        onChange={(e) => handleInputChange(e)}
                                        hidden
                                    />
                                    <label
                                        className={`customLabel-kyc_acko ${formData?.kycAadharImg && "customLabel-kyc_acko_active"} `}
                                        htmlFor="uploadImg-Aadhar"
                                    >
                                        <p className="btn-kyc_acko">
                                            <img
                                                src={
                                                    formData?.kycAadharImg ? blueUpload : upload
                                                }
                                            />
                                            {
                                                formData?.kycAadharImg ? "Re-Upload File" : "Upload File"
                                            }
                                        </p>
                                    </label>
                                </div>
                                {
                                    errors?.kycAadharImg && (
                                        <p className="err-msg"> {errors?.kycAadharImg} </p>
                                    )
                                }
                            </div>
                        ) : (
                            <div className="inputGropOne_form inputGrop ">
                                <div className="inputGrop_modal_close_container">
                                    <img src={close} onClick={() => setShowUploadModal(false)} />
                                </div>
                                <p className="kyc_label_acko">
                                    Upload Aadhar Back Image
                                </p>
                                <div className={`inputGropOne_form inputGrop inputGropKyc-custom_acko_kyc ${errors?.kycAadharImgBack && 'custom_acko_kyc_err'} `}>
                                    {formData?.kycAadharImgBack ? (
                                        <span className="successFull_msg">
                                            Photo Uploaded Successfully
                                        </span>
                                    ) : (
                                        <p>JPEG, PNG or JPG formats only</p>
                                    )}
                                    <input
                                        type="file"
                                        className="hideRealUploadBtn"
                                        id="kycAadharImgBack"
                                        accept="image/*, application/pdf"
                                        name="kycAadharImgBack"
                                        onChange={(e) => handleInputChange(e)}
                                        hidden
                                    />
                                    <label
                                        className={`customLabel-kyc_acko ${formData?.kycAadharImgBack
                                            ? "customLabel-kyc_acko_active"
                                            : ""
                                            } `}
                                        htmlFor="kycAadharImgBack"
                                    >
                                        <p className="btn-kyc_acko">
                                            <img
                                                src={
                                                    formData?.kycAadharImgBack
                                                        ? blueUpload
                                                        : upload
                                                }
                                            />
                                            {
                                                formData?.kycAadharImgBack ? "Re-Upload File" : "Upload File"
                                            }
                                        </p>
                                    </label>
                                </div>
                                {
                                    errors.kycAadharImgBack && (
                                        <p className="err-msg" > {errors?.kycAadharImgBack} </p>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </Modal>
        </>
    );
};

let kycContent = `From January 1, 2023, KYC authentication has been a mandatory process to purchase insurance policies from all insurance providers in India.`
let kycContentTwo = `Your details are safe with us. You can complete your KYC in 1 min.`

export default AckoKycForm;
