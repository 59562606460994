import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar/Navbar";
import "./App.css";
import "../src/components/style/Global.css";
import Footer from "./components/Footer/Footer";
import { HashRouter, Routes, Route, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import testimonial from "./Assets/Images/testimonial.png";
import quotes from "./Assets/Images/quotes.png";
import forward from "./Assets/Images/forward.png";
// PARTNER'S LOGO
import tata from "./Assets/Partners_logo/TATA AIG.png";
import acko from "./Assets/Partners_logo/acko.png";
import kotak from "./Assets/Partners_logo/kOTAK.png";
import HDFC from "./Assets/Partners_logo/hdfc.png";
import newIndia from "./Assets/Partners_logo/new india.png";
import icici from "./Assets/Partners_logo/icici lombard.svg";
import royal from "./Assets/Partners_logo/royal sundaram.png";
import cholaMs from "./Assets/Partners_logo/chola.png";
import oriental from "./Assets/Partners_logo/Oriental.png";
import raheja from "./Assets/Partners_logo/Raheja.png";
import reliance from "./Assets/Partners_logo/reliance.png";
import sbi from "./Assets/Partners_logo/SBI.png";
import shriram from "./Assets/Partners_logo/shriram.png";
import bajaj from "./Assets/Partners_logo/bajaj.svg";
import digit from "./Assets/Partners_logo/digit.svg";
import edelweiss from "./Assets/Partners_logo/zuno.png";
import future from "./Assets/Partners_logo/future.png";
import united from "./Assets/Partners_logo/united.png";
import magma from "./Assets/Partners_logo/magma.png";
import national from "./Assets/Partners_logo/national.jpg";
import navi from "./Assets/Partners_logo/navi.jpg";
import iffco from "./Assets/Partners_logo/iffco tokio.svg";
import liberty from "./Assets/Partners_logo/liberty_logo.jpeg";
import sompo from "./Assets/Partners_logo/sompo.png";
// CAR BRANDS LOGO---------------->
import ashokLeyland from "./Assets/Car_Logo_Final/ashok leyland.png";
import astonMartin from "./Assets/Car_Logo_Final/aston martin.png";
import audi from "./Assets/Car_Logo_Final/audi.png";
import bentley from "./Assets/Car_Logo_Final/bentley.png";
import benz from "./Assets/Car_Logo_Final/benz.png";
import bmw from "./Assets/Car_Logo_Final/bmw.png";
import buggati from "./Assets/Car_Logo_Final/buggati.png";
import chevrolet from "./Assets/Car_Logo_Final/chevrolet.png";
import citroen from "./Assets/Car_Logo_Final/citroen.png";
import datsun from "./Assets/Car_Logo_Final/datsun.png";
import ferrari from "./Assets/Car_Logo_Final/ferrari.png";
import fiat from "./Assets/Car_Logo_Final/fiat.png";
import force from "./Assets/Car_Logo_Final/force.png";
import ford from "./Assets/Car_Logo_Final/ford.png";
import honda from "./Assets/Car_Logo_Final/honda.png";
import hummer from "./Assets/Car_Logo_Final/hummer.png";
import hyundai from "./Assets/Car_Logo_Final/hyundai.png";
import isuzu from "./Assets/Car_Logo_Final/isuzu.png";
import jaguar from "./Assets/Car_Logo_Final/jaguar.png";
import jeep from "./Assets/Car_Logo_Final/jeep.png";
import kia from "./Assets/Car_Logo_Final/kia.png";
import lamborghini from "./Assets/Car_Logo_Final/lamborghini.png";
import landRover from "./Assets/Car_Logo_Final/land rover.png";
import lexus from "./Assets/Car_Logo_Final/lexus.png";
import mahindra from "./Assets/Car_Logo_Final/mahindra.png";
import maserati from "./Assets/Car_Logo_Final/maserati.png";
import MG from "./Assets/Car_Logo_Final/MG.png";
import miniCooper from "./Assets/Car_Logo_Final/mini cooper.png";
import mitsubishi from "./Assets/Car_Logo_Final/mitsubishi.png";
import nissan from "./Assets/Car_Logo_Final/nissan.png";
import opel from "./Assets/Car_Logo_Final/opel.png";
import porsche from "./Assets/Car_Logo_Final/porsche.png";
import premier from "./Assets/Car_Logo_Final/premier.png";
import renault from "./Assets/Car_Logo_Final/renault.png";
import rollsRoyce from "./Assets/Car_Logo_Final/Rolls royce.png";
import Skoda from "./Assets/Car_Logo_Final/Skoda.png";
import ssangyong from "./Assets/Car_Logo_Final/ssangyong.png";
import suzuki from "./Assets/Car_Logo_Final/suzuki.png";
import tataImg from "./Assets/Car_Logo_Final/tata.png";
import toyota from "./Assets/Car_Logo_Final/toyota.png";
import Volkswagen from "./Assets/Car_Logo_Final/Volkswagen.png";
import Volvo from "./Assets/Car_Logo_Final/Volvo.png";
import chrysler from "./Assets/Car_Logo_Final/Chrysler Logo.png";
import daewoo from "./Assets/Car_Logo_Final/Daewoo Logo.png";
import hindustanMotors from "./Assets/Car_Logo_Final/Hindustan Motors.png";
import icml from "./Assets/Car_Logo_Final/ICML LOGO.png";
import mahindraRenault from "./Assets/Car_Logo_Final/Mahindra Renault Logo.png";
import mahindraReva from "./Assets/Car_Logo_Final/Mahindra Reva Logo.png";
import maruti from "./Assets/Car_Logo_Final/Maruti logo.png";
import reva from "./Assets/Car_Logo_Final/Reva Logo.png";
// COMPONENTS --------------->
import LandingPage from "./components/LandingPage/LandingPage";
import ViewPlans from "./components/ViewPlans/ViewPlans";
import ViewPlansBike from "./components/ViewPlans/ViewPlansBike";
import ProposalForm from "./components/ProposalForm/ProposalForm";
import ProposalFormBike from "./components/ProposalForm/ProposalFormBike";
import FindMyCarDetails from "./components/RegNumberForm/FindMyCarDetails";
import FindMyBikeDetails from "./components/RegNumberForm/FindMyBikeDetails";
import Login from "./components/Pages/Login/Login";
import Redirect from "./components/Payment/Redirect";
import Loader from "./components/Pages/Loader/Loader";
import PaymentFailed from "./components/Payment/PaymentFailed";
import ClientAccount from "./components/Pages/User Profile/ClientAccount";
import IntimateClaim from "./components/Pages/User Profile/Intimate Claim/IntimateClaim";
import AboutUs from "./components/Pages/About Us/AboutUs";
import CarInsurance from "./components/Pages/Car insurance/CarInsurance";
import ComprehensiveCarIns from "./components/Pages/Car insurance/ComprehensiveCarIns";
import ThirdPartyIns from "./components/Pages/Car insurance/thirdPartyIns";
import StandaloneIns from "./components/Pages/Car insurance/StandaloneIns";
import ZeroDepreciation from "./components/Pages/Car insurance/ZeroDepreciation";
import { currentYear } from "./utils/commonUtils";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import { useLocation } from "react-router-dom";
import { getDealerURLAction } from "./redux/Actions/TransactionAction"
import { dealerDataAction } from "./redux/Reducers/dealerDataReducer";
import ContactUs from "./components/Pages/Contact us/ContactUs";
import Claim from "./components/Pages/Claim/Claim";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy/PrivacyPolicy";
import PolicyCancellation from "./components/Pages/PolicyCancellation/PolicyCancellation";
import Grievance from "./components/Pages/Greivance/Grievance";
import AckoKycForm from "./components/ProposalForm/AckoKycForm";


const Root = () => {
  const getVehicleData = useSelector((state) => {
    return state?.getVehicleData?.data?.data?.data;
  });

  const getSbiQuickQuoteData = useSelector((state) => {
    return state?.sbiQuickQuote?.data?.body?.data;
  });
  const isAuthenticated = useSelector((state) => {
    return state?.getLoggedInStatus?.data?.isLoggedIn
  })
  const dispatch = useDispatch();
  const search = useLocation().search;

  const dealer_code_from_url = new URLSearchParams(search).get("Franchise_ID");
  const dealer_code_from_local_storage = localStorage.getItem('dealer_code');

  if (dealer_code_from_url) {
    localStorage.setItem('dealer_code', dealer_code_from_url);
  } else if (dealer_code_from_local_storage) {
    localStorage.setItem('dealer_code', dealer_code_from_local_storage);
  }

  const dealer_code = dealer_code_from_url ? dealer_code_from_url : dealer_code_from_local_storage;

  //fetch dealers details to display
  useEffect(() => {
    async function getDealerDetails() {
      if (dealer_code) {
        // call API
        let dealerDetails = await dispatch(getDealerURLAction(dealer_code));

        if (dealerDetails?.payload.status) {
          dispatch(
            dealerDataAction({
              address: dealerDetails?.payload.data.address,
              dealer_name: dealerDetails?.payload.data.dealer_name,
              mobile_no: dealerDetails?.payload.data.mobile_no,
              dealer_code: dealer_code,
              dealer_logo: dealerDetails?.payload.data.dealer_logo
            })
          );
        } else {
          dispatch(
            dealerDataAction({
              address: "",
              dealer_name: "",
              dealer_code: "",
              dealer_logo: "",
              mobile_no: "770 0991 251"
            })
          );
        }
      } else {
        dispatch(
          dealerDataAction({
            address: "",
            dealer_name: "",
            dealer_code: "",
            dealer_logo: "",
            mobile_no: "770 0991 251"
          })
        );
      }
    }
    getDealerDetails()
  }, [dealer_code])

  return (
    <div>
      {/* <HashRouter> */}
      <Routes>
        <Route
          path="/"
          element={
            // <AckoKycForm
            //   bottomFooter={bottomFooter}
            // />
            <LandingPage
              firstLot={firstLot}
              secondLot={secondLot}
              carQueries={carQueries}
              bikeQueries={bikeQueries}
              healthQueries={healthQueries}
              taxiQueries={taxiQueries}
              testimonials={testimonials}
            />
          }
        />
        <Route
          path="/partners"
          element={
            <LandingPage
              firstLot={firstLot}
              secondLot={secondLot}
              carQueries={carQueries}
              bikeQueries={bikeQueries}
              healthQueries={healthQueries}
              taxiQueries={taxiQueries}
              testimonials={testimonials}
            />
          }
        />
        <Route
          path="/View-Plans"
          element={
            <ViewPlans
              firstLot={firstLot}
              secondLot={secondLot}
              carQueries={carQueries}
              bikeQueries={bikeQueries}
              healthQueries={healthQueries}
              taxiQueries={taxiQueries}
              testimonials={testimonials}
              vehicleData={getVehicleData}
              MakeList={carBrandsOne}
              sbiQUickQuoteData={getSbiQuickQuoteData}
            />
          }
        />
        <Route
          path="/View-Plans-Bike"
          element={
            <ViewPlansBike
              firstLot={firstLot}
              secondLot={secondLot}
              carQueries={carQueries}
              bikeQueries={bikeQueries}
              healthQueries={healthQueries}
              taxiQueries={taxiQueries}
              testimonials={testimonials}
              vehicleData={getVehicleData}
              MakeList={carBrandsOne}
              sbiQUickQuoteData={getSbiQuickQuoteData}
            />
          }
        />
        <Route
          path="/Propsal-Form"
          element={
            <ProposalForm
              bottomFooter={bottomFooter}
              collapsibleTitle={collapsibleTitle}
              headerCollapsible={headerCollapsible}
              insurerLogo={insurerLogo}
            />
          }
        />
        <Route
          path="/Propsal-Form-Bike"
          element={
            <ProposalFormBike
              bottomFooter={bottomFooter}
              collapsibleTitle={collapsibleTitle}
              headerCollapsible={headerCollapsible}
              insurerLogo={insurerLogo}
            />
          }
        />
        <Route
          path="/Find-My-Car"
          element={<FindMyCarDetails sectionOne={carBrandsOne} />}
        />
        <Route path="/Find-My-Bike" element={<FindMyBikeDetails />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Payment-Redirect" element={<Redirect />} />
        <Route path="/Loader" element={<Loader />} />
        <Route path="/Payment-Failed" element={<PaymentFailed />} />
        <Route path="/My-Account" element={isAuthenticated ? <ClientAccount /> : <Login />} />
        <Route path="/Claim-Intimation" element={<IntimateClaim />} />
        <Route path="/About-us" element={<AboutUs />} />
        <Route
          path="/Car-Insurance"
          element={<CarInsurance firstLot={firstLot} secondLot={secondLot} />}
        />
        <Route
          path="/Comprehensive-policy"
          element={
            <ComprehensiveCarIns firstLot={firstLot} secondLot={secondLot} />
          }
        />
        <Route
          path="/Third-party-policy"
          element={
            <ThirdPartyIns firstLot={firstLot} secondLot={secondLot} />
          }
        />
        <Route
          path="/Standalone-policy"
          element={
            <StandaloneIns firstLot={firstLot} secondLot={secondLot} />
          }
        />
        <Route
          path="/Zero-depreciation"
          element={
            <ZeroDepreciation firstLot={firstLot} secondLot={secondLot} />
          }
        />
        <Route path="*" element={<PageNotFound />} />
        <Route
          path="/Contact-us"
          element={
            <ContactUs />
          }
        />
        <Route
          path="/Claim"
          element={
            <Claim firstLot={firstLot} secondLot={secondLot} />
          }
        />
        <Route
          path="/Privacy-policy"
          element={
            <PrivacyPolicy />
          }
        />
        <Route
          path="/Policy-cancellation"
          element={
            <PolicyCancellation />
          }
        />
        <Route
          path="/Grievance-redressal"
          element={
            <Grievance />
          }
        />
        <Route
          path="/Kyc-Form"
          element={
            <AckoKycForm
              bottomFooter={bottomFooter}
            />
          }
        />
      </Routes>
      {/* </HashRouter> */}
    </div>
  );
};

// CAR BRANDS LOGO

const carBrandsOne = [
  {
    id: 1,
    reqId: 4,
    img: suzuki,
    name: "Maruti Suzuki",
    make: "Maruti Suzuki",
  },
  {
    id: 2,
    reqId: 18,
    img: honda,
    name: "Honda",
    make: "Honda",
  },
  {
    id: 3,
    reqId: 2,
    img: tataImg,
    name: "Tata",
    make: "Tata",
  },
  {
    id: 4,
    reqId: 14,
    img: toyota,
    name: "Toyota",
    make: "Toyota",
  },
  {
    id: 5,
    reqId: 22,
    img: chevrolet,
    name: "Chevrolet",
    make: "Chevrolet",
  },
  {
    id: 6,
    reqId: 17,
    img: Volkswagen,
    name: "Volkswagen",
    make: "Volkswagen",
  },
  {
    id: 7,
    reqId: 43,
    img: mahindra,
    name: "Mahindra",
    make: "Mahindra",
  },
  {
    id: 8,
    reqId: 805,
    img: datsun,
    name: "Datsun",
    make: "Datsun",
  },
  {
    id: 9,
    reqId: 80,
    img: mitsubishi,
    name: "Mitsubishi",
    make: "Mitsubishi",
  },
  {
    id: 10,
    reqId: 1,
    img: hyundai,
    name: "Hyundai",
    make: "Hyundai",
  },
  {
    id: 11,
    reqId: 804,
    img: ashokLeyland,
    name: "Ashok leyland",
    make: "Ashok leyland",
  },
  {
    id: 12,
    reqId: 936,
    img: astonMartin,
    name: "Aston Martin",
    make: "Aston Martin",
  },
  {
    id: 13,
    reqId: 20,
    img: audi,
    name: "Audi",
    make: "Audi",
  },
  {
    id: 14,
    reqId: 769,
    img: bentley,
    name: "Bentley",
    make: "Bentley",
  },
  {
    id: 15,
    reqId: 44,
    img: benz,
    name: "Mercedes-Benz",
    make: "Mercedes-Benz",
  },
  {
    id: 16,
    reqId: 71,
    img: bmw,
    name: "BMW",
    make: "BMW",
  },
  {
    id: 17,
    reqId: 1710,
    img: buggati,
    name: "Bugatti",
    make: "Bugatti",
  },
  {
    id: 18,
    reqId: 335,
    img: citroen,
    name: "Citroen",
    make: "Citroen",
  },
  {
    id: 19,
    reqId: 781,
    img: ferrari,
    name: "Ferrari",
    make: "Ferrari",
  },
  {
    id: 20,
    reqId: 41,
    img: fiat,
    name: "Fiat",
    make: "Fiat",
  },
  {
    id: 21,
    img: force,
    reqId: 162,
    name: "Force",
    make: "Force",
  },
  {
    id: 22,
    img: ford,
    reqId: 61,
    name: "Ford",
    make: "Ford",
  },
  {
    id: 27,
    reqId: 327,
    img: kia,
    name: "kia",
    make: "kia",
  },
  {
    id: 25,
    reqId: 33,
    img: jaguar,
    name: "Jaguar",
    make: "Jaguar",
  },
  {
    id: 26,
    reqId: 51,
    img: jeep,
    name: "Jeep",
    make: "Jeep",
  },

  {
    id: 28,
    reqId: 1008,
    img: lamborghini,
    name: "Lamborghini",
    make: "Lamborghini",
  },
  {
    id: 29,
    reqId: 737,
    img: landRover,
    name: "Land rover",
    make: "Land rover",
  },
  {
    id: 30,
    img: lexus,
    reqId: 939,
    name: "Lexus",
    make: "Lexus",
  },
  // {
  //   id: 48,
  //   img: maruti,
  //   reqId: 4,
  //   name: "Maruti",
  //   make: "Maruti",
  // },
  {
    id: 42,
    reqId: 191,
    img: Volvo,
    name: "Volvo",
    make: "Volvo",
  },
  {
    id: 31,
    reqId: 726,
    img: maserati,
    name: "Maserati",
    make: "Maserati",
  },
  {
    id: 32,
    reqId: 34,
    img: MG,
    name: "MG",
    make: "MG",
  },
  {
    id: 33,
    reqId: 615,
    img: miniCooper,
    name: "Mini Cooper",
    make: "Mini Cooper",
  },
  {
    id: 34,
    reqId: 35,
    img: nissan,
    name: "Nissan",
    make: "Nissan ",
  },
  {
    id: 24,
    reqId: 563,
    img: isuzu,
    name: "Isuzu",
    make: "Isuzu",
  },
  {
    id: 35,
    reqId: 1004,
    img: opel,
    name: "Opel",
    make: "Opel",
  },
  {
    id: 36,
    reqId: 3,
    img: porsche,
    name: "Porsche",
    make: "Porsche",
  },
  {
    id: 37,
    reqId: 746,
    img: premier,
    name: "Premier",
    make: "Premier",
  },
  {
    id: 38,
    reqId: 45,
    img: renault,
    name: "Renault",
    make: "Renault",
  },
  {
    id: 39,
    reqId: 1379,
    img: rollsRoyce,
    name: "Rolls Royce",
    make: "Rolls Royce",
  },
  {
    id: 40,
    reqId: 93,
    img: Skoda,
    name: "Skoda",
    make: "Skoda",
  },
  {
    id: 41,
    reqId: 3483,
    img: ssangyong,
    name: "Ssangyong",
    make: "Ssangyong",
  },
  {
    id: 43,
    reqId: 5944,
    img: chrysler,
    name: "Chrysler",
    make: "Chrysler",
  },
  {
    id: 43,
    reqId: 2755,
    img: daewoo,
    name: "Daewoo",
    make: "Daewoo",
  },
  {
    id: 23,
    reqId: 1383,
    img: hummer,
    name: "Hummer",
    make: "Hummer",
  },
  {
    id: 47,
    reqId: 5842,
    img: mahindraReva,
    name: "Mahindra Reva",
    make: "Mahindra Reva",
  },
  {
    id: 44,
    reqId: 794,
    img: hindustanMotors,
    name: "Hindustan Motors",
    make: "Hindustan Motors",
  },
  {
    id: 45,
    reqId: 887,
    img: icml,
    name: "ICML",
    make: "ICML",
  },
  {
    id: 46,
    reqId: 5,
    img: mahindraRenault,
    name: "Mahindra Renault",
    make: "Mahindra Renault",
  },
  {
    id: 49,
    img: reva,
    reqId: 1083,
    name: "Reva",
    make: "Reva",
  },
  {
    id: 50,
    reqId: 953,
    img: ssangyong,
    name: "Mahindra Ssangyong",
    make: "Mahindra Ssangyong",
  },
];

// PARTNER'S LOGO

const firstLot = [sbi, shriram, digit, acko, icici];

const secondLot = [
  tata,
  cholaMs,
  oriental,
  reliance,
  kotak,
  HDFC,
  bajaj,
  newIndia,
  edelweiss,
  future,
  magma,
  national,
  navi,
  iffco,
  liberty,
  raheja,
  royal,
  united,
  sompo,
];

const insurerLogo = [
  tata,
  kotak,
  HDFC,
  newIndia,
  icici,
  cholaMs,
  oriental,
  reliance,
  sbi,
  shriram,
  bajaj,
  digit,
  edelweiss,
  future,
  magma,
  national,
  navi,
  iffco,
  liberty,
  raheja,
  royal,
  united,
  sompo,
];

// ACCORDIAN CONTENT

const carQueries = [
  {
    question: "What is IRDAI?",
    answer:
      "IRDA stands for the Insurance Regulatory and development Authority. It is the apex regulatory body of insurance in India. All insurance companies have to comply with the rules prescribed by the IRDA for selling their insurance policies.",
  },
  {
    question: "What is a car insurance policy?",
    answer:
      "Car insurance is a contract where you pay premiums to an insurance company, and in return, they cover costs related to accidents, injuries, and damages to your vehicle. It includes liability, collision, and comprehensive coverage.",
  },
  {
    question: "Is Car Insurance mandatory in India?",
    answer:
      "Yes, car insurance is mandatory in India as per the Motor Vehicles Act, requiring all vehicle owners to have at least third-party liability coverage.",
  },
  {
    question: "What are the factors that affects Car Insurance Premium?",
    answer:
      "Car insurance premiums are influenced by factors such as the driver's age, vehicle type, driving record, location, and coverage type. Safer drivers, lower-risk vehicles, and favorable circumstances typically lead to lower insurance costs.",
  },
  {
    question: "Is car insurance also required for old cars?",
    answer:
      "Yes, car insurance is necessary for old cars. While mandatory third-party coverage is a legal requirement, having comprehensive insurance is recommended for protection against damages and unforeseen events.",
  },
  {
    question:
      "How much fine do I have to pay for not holding car insurance while driving?",
    answer:
      "Not having car insurance can lead to fines, with amounts varying based on the specific circumstances. It's a legal requirement to have at least third-party liability insurance, and failure to comply may result in penalties and license suspension.",
  },
];

const bikeQueries = [
  {
    question: "Is Bike Insurance Mandatory In India?",
    answer:
      "Yes, bike insurance is mandatory in India as per the Motor Vehicles Act. It is a legal requirement to have at least third-party liability coverage for bikes.",
  },
  {
    question: "How many types of Bike Insurance are available?",
    answer:
      "Bike insurance in India comes in two types: one that covers damages to others (Third-Party), required by law, and another (Comprehensive) that covers both others and your bike for accidents and more.",
  },
  {
    question: "Which Insurance is compulsary for Two-Wheelers?",
    answer:
      "For two-wheelers in India, having Third-Party Liability Insurance is compulsory by law, covering damages to others.",
  },
  {
    question: "Can we claim Bike Insurance without an accident?",
    answer:
      "Yes, bike insurance claims are possible without an accident. Comprehensive coverage includes claims for theft, fire, natural disasters, or vandalism, not just accidents.",
  },
  {
    question: "How much GST is on Two-Wheeler Insurance?",
    answer:
      "As per the taxation norms in India, you must pay a GST of 18% on your two-wheeler insurance premium.",
  },
  {
    question: "What are the disadvantages of third-party bike insurance?",
    answer:
      "Disadvantages of third-party bike insurance include limited coverage, no protection for own bike damages, and the absence of coverage for theft or accidents.",
  },
];

const healthQueries = [
  {
    question: "What is the right age to buy health insurance?",
    answer:
      "Secure health insurance early, preferably in your 20s or 30s, for lower premiums and coverage before potential health issues. Yet, obtaining coverage at any age is wise to safeguard against unexpected medical expenses.",
  },
  {
    question: "Is a medical test mandatory to buy a health insurance policy?",
    answer:
      "Medical tests for health insurance vary. While not always mandatory, they may be required based on factors like age and coverage amount. Check with the insurance provider for specific requirements.",
  },
  {
    question:
      "What does cashless hospitalization mean in a health insurance policy?",
    answer:
      "Cashless hospitalization in health insurance allows policyholders to receive treatment at network hospitals without upfront payments. The insurer settles the bills directly with the hospital, offering convenience and ensuring timely medical care.",
  },
  {
    question:
      "What are the documents required for purchasing a health insurance policy?",
    answer:
      "For health insurance, you usually need ID, address proof, age verification, and photos. Additional medical documents or check-ups may be required based on age and coverage. Check with the insurer for specific requirements.",
  },
  {
    question:
      "What are pre and post-hospitalization expenses in health insurance?",
    answer:
      "Pre-hospitalization covers costs before admission (e.g., tests), while post-hospitalization includes expenses after discharge (e.g., follow-up visits). Both are reimbursed in comprehensive health insurance.",
  },
  {
    question:
      "What if I already have a health insurance policy, but just want to increase my sum insured?",
    answer:
      "To increase your health insurance sum insured, submit a request to your insurer. They'll assess factors like health and age, and the enhanced coverage typically starts from the next policy renewal, with an additional premium.",
  },
];

const taxiQueries = [
  {
    question: "What does taxi insurance cover?",
    answer:
      "Taxi insurance typically covers third-party liability. Comprehensive policies may include coverage for the taxi, passengers, and personal belongings.",
  },
  {
    question: "How is the premium calculated?",
    answer:
      "Premium calculation considers factors like driver experience, location, and the type of taxi. These variables influence the final premium amount.",
  },
  {
    question: "What are the policy exclusions?",
    answer:
      "Exclusions may include situations not covered by the policy. It's crucial to understand these limitations for a comprehensive assessment of potential risks.",
  },
  {
    question: "How do I file a claim, and how quickly is it processed?",
    answer:
      "To file a claim, follow the specified procedure and provide necessary documentation. Claim processing times vary, and it's essential to be familiar with the timeline.",
  },
  {
    question: "Does the policy offer a No Claims Bonus?",
    answer:
      "Yes, many policies offer a No Claims Bonus. It rewards policyholders for claim-free periods and can lead to a reduction in the premium.",
  },
  {
    question: "Are there additional coverage options or riders available?",
    answer:
      "Yes, additional coverage options or riders may include breakdown assistance, legal expenses, or loss of income. Check with your insurer for available add-ons.",
  },
];

// testimonial content

const testimonials = [
  {
    name: `Sylvester D'souza`,
    image: testimonial,
    quotes: quotes,
    forward: forward,
    designation: "CE0",
    company: "ABC Company",
    message: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's`,
  },
  {
    name: "Marshall Bruce Mathers",
    image: testimonial,
    quotes: quotes,
    forward: forward,
    designation: "Business Manager",
    company: "ABC Company",
    message: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's`,
  },
  {
    name: "Slim Shady",
    image: testimonial,
    quotes: quotes,
    forward: forward,
    designation: "Product Manager",
    company: "ABC Company",
    message: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's`,
  },
];


const bottomFooter = [
  {
    contentOne: `Website Approved by IRDAI(Govt of India) IRDAI Direct Broker Licence
        No. 762*Insurance is the subject matter of solicitation. Visitors
        are hereby informed that their information submitted on the website
        may be shared with insurers. The product information for comparison
        displayed on this website is of the insurers with whom our company
        has an agreement.Product information is authentic and solely based
        on the information received from the Insurer. *The information
        provided on this website/page is for information purpose
        only.BookMyInsurance does not in any form or manner endorse the
        information so provided on the website and strives to provide
        factual and unbiased information to customers to assist in making
        informed insurance choices.`,
    contentTwo: `© ${currentYear} Viaansh Insurance Brokers Pvt. Ltd.`,
    contentThree: "All rights reserved.",
    contentFour:
      "BookMyInsurance and BookMyInsurance Logo are registered trademarks",
    contentFive: "of Viaansh Insurance Brokers Pvt. Ltd.",
  },
];

// proposal collapsible content ->

let collapsibleTitle = [
  "Owner Details",
  "Personal Details",
  "Address Details",
  "Vehicle Details",
];

let headerCollapsible = [
  "Owner Name",
  "Email ID",
  "Phone Number",
  "PAN Number",
  "Gender",
  "Maritial Status",
  "Date of Birth",
  "Nominee Name",
  "Age",
  "Relation",
  "Address Line 1",
  "Address Line 2",
  "State",
  "City",
  "Pin Code ",
  "Previous Policy Number",
  "Previous Policy Insurer",
  "Chasis Number",
  "Engine Number",
  "Car is on Loan?",
  "Car Hypothecation",
];

export default Root;
